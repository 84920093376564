var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    _vm._l(_vm.formConfigKey, function (item, index) {
      return _c("div", { key: index, staticClass: "el-descriptions" }, [
        _c("div", { staticClass: "el-descriptions__header" }, [
          _c("div", { staticClass: "el-descriptions__title" }, [
            _vm._v(_vm._s(item.title)),
          ]),
          _c("div", { staticClass: "el-descriptions__extra" }),
        ]),
        _c(
          "div",
          { staticClass: "el-descriptions__body" },
          [
            _vm.formConfig.componentsStatus == "edit" && index == 2
              ? _c(
                  "el-form",
                  {
                    ref: "formData",
                    refInFor: true,
                    attrs: { model: _vm.formData, "label-width": "120px" },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请填写备注",
                                      trigger: "blur",
                                    },
                                  ],
                                  prop: "remark",
                                  label: "备注",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请填写备注",
                                    type: "textarea",
                                  },
                                  model: {
                                    value: _vm.formData.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "remark", $$v)
                                    },
                                    expression: "formData.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "el-form",
                  { attrs: { "label-width": "120px" } },
                  [
                    _c(
                      "el-row",
                      _vm._l(item.opthions, function (itemss, indexss) {
                        return _c(
                          "el-col",
                          {
                            key: "" + index + "" + indexss,
                            attrs: { span: itemss.span || 8 },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: itemss.label } },
                              [
                                !itemss.type || itemss.type == "input"
                                  ? [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: "",
                                          value:
                                            _vm.detailDdata[itemss.valCode],
                                        },
                                      }),
                                    ]
                                  : itemss.type == "image"
                                  ? _vm._l(
                                      _vm.detailDdata[itemss.valCode],
                                      function (imgItem, imgIdx) {
                                        return _c("el-image", {
                                          key: imgIdx + imgItem,
                                          staticClass: "image_box",
                                          attrs: {
                                            src: imgItem,
                                            "preview-src-list":
                                              _vm.detailDdata[itemss.valCode],
                                          },
                                        })
                                      }
                                    )
                                  : itemss.type == "textarea"
                                  ? [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          disabled: "",
                                          value:
                                            _vm.detailDdata[itemss.valCode],
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }