<template>
  <div v-loading="loading">
    <div v-for="(item,index) in formConfigKey" :key='index' class="el-descriptions">
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">{{item.title}}</div>
        <div class="el-descriptions__extra"></div>
      </div>
      <div class="el-descriptions__body">
        <el-form v-if='formConfig.componentsStatus == "edit" && index == 2' ref='formData' :model="formData"
          label-width="120px">
          <el-row>
            <el-col :span='12'>
              <el-form-item :rules="[{ required: true, message: '请填写备注', trigger: 'blur' }]" prop="remark" label="备注">
                <el-input placeholder='请填写备注' type="textarea" v-model="formData.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form v-else label-width="120px">
          <el-row>
            <el-col v-for="(itemss,indexss) in item.opthions" :key='`${index}` + `${indexss}`' :span='itemss.span || 8'>
              <el-form-item :label="itemss.label">
                <template v-if='!itemss.type || itemss.type == "input"'>
                  <el-input disabled :value="detailDdata[itemss.valCode]" />
                </template>
                <template v-else-if='itemss.type == "image"'>
                  <el-image v-for="(imgItem,imgIdx) in detailDdata[itemss.valCode]" :key='imgIdx + imgItem'
                    class="image_box" :src="imgItem" :preview-src-list="detailDdata[itemss.valCode]" />
                </template>
                <template v-else-if='itemss.type == "textarea"'>
                  <el-input type='textarea' disabled :value="detailDdata[itemss.valCode]" />
                </template>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';

export default {
  props: ['formConfig'],
  name: 'detailPage',
  data() {
    return {
      loading: false,
      formData: {
        remark: '',
      },
      detailDdata: {},
      formConfigKey: [
        {
          title: '用户信息',
          opthions: [
            { label: '终端编码', valCode: 'participatorCode' },
            { label: '终端名称', valCode: 'participatorName' },
            { label: '终端类型', valCode: 'terminalTypeName' },
            { label: '客户组织', valCode: 'orgName' },
            { label: '所属组织', valCode: 'cusOrgName' },
            { label: '渠道', valCode: 'channelName' },
          ],
        },
        {
          title: '意见反馈信息',
          opthions: [
            { label: '意见主题', valCode: 'title' },
            { label: '意见描述', valCode: 'description' },
            { label: '提交时间', valCode: 'createTime' },
            {
              label: '反馈图片', valCode: 'pictureAddresses', type: 'image', span: 24,
            },
          ],
        },
        {
          title: '意见反馈处理',
          opthions: [
            { label: '处理状态', valCode: 'handleStatusName' },
            { label: '处理时间', valCode: 'reviewDate' },
            { label: '备注', valCode: 'remark', type: 'textarea' },
          ],
        },
      ],
    };
  },
  created() {
    if (this.formConfig.id) {
      this.getSelectData();
    }
  },
  methods: {
    findDictValue(code, dict) {
      const item = dict.find((v) => v.dictCode === code);
      return item ? item.dictValue : '';
    },
    async getUserInfoObjDetails(id = '', batchDictSelect) {
      request.get('/cps/v1/customer/feedback/findDetailById', { id }).then(({ result, success }) => {
        this.loading = false;
        console.log(result);
        if (success) {
          this.detailDdata = {
            ...result,
            terminalTypeName: result.terminalType ? this.findDictValue(result.terminalType, batchDictSelect.terminal_type) : '',
            handleStatusName: result.handleStatus ? this.findDictValue(result.handleStatus, batchDictSelect.cps_feedback_status) : '',
            channelName: result.channel ? this.findDictValue(result.channel, batchDictSelect.channel) : '',
            pictureAddresses: result.pictureAddresses ? result.pictureAddresses.split(',') : [],
          };
        }
      }).catch((err) => {
        this.loading = false;
      });
    },
    close() {
      this.$emit('onClose');
    },
    async getSelectData() {
      this.loading = true;
      try {
        const res = await request.post('/mdm/mdmdictdata/batchDictSelect', ['terminal_type', 'channel', 'cps_feedback_status']);
        this.getUserInfoObjDetails(this.formConfig.id, res.result);
      } catch (err) {
        this.loading = false;
      }
    },
    handleBtn(callback) {
      this.$refs.formData[0].validate((valid) => {
        if (valid && callback) {
          callback(
            {
              ...this.formData,
              id: this.formConfig.id,
            },
          );
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .marT_20 {
    margin-top: 20px;
  }

  /deep/.el-descriptions-item__label {
    white-space: nowrap !important;
  }

  .image_box {
    /deep/ img {
      width: 150px;
    }
  }
</style>
