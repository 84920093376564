import TablePage from '@/found/components/table_page';
import DatePicker from './components/datePicker';
import DetailPage from './components/detailPage';
import request from '../../../../../../utils/request';

export default {
  name: 'package',
  extends: TablePage,
  components: {
    DatePicker,
    DetailPage,
  },
  data() {
    return {
      requestUrl: '/cps/v1/customer/feedback/findByConditions',
      requestType: 'GET',
      formConfig: {},
      isCalculateHeight: false,
    };
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('packageList');
  },
  methods: {
    clickVisible({ val, row }) {
      if (val.code === 'particulars' && row.handleStatus === 'no_deal_with') {
        return false;
      }
      if (val.code === 'work-flow-deal' && row.handleStatus === 'deal_with') {
        return false;
      }
      return true;
    },
    // 弹窗确定事件
    onModalOk() {
      this.$refs.modalForm.handleBtn(async (formData) => {
        this.$refs.modalForm.loading = true;
        try {
          const res = await request.patch('/cps/v1/customer/feedback', formData);
          if (res.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.closeModal();
            this.getList();
          }
          this.$refs.modalForm.loading = false;
        } finally {
          this.$refs.modalForm.loading = false;
        }
      });
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'particulars') {
        this.formConfig = { ...row, componentsStatus: 'view' };
        this.modalConfig.title = '意见反馈详情';
        this.formName = 'DetailPage';
        this.modalConfig.showFooter = false;
        this.openFull();
      } else if (val.code === 'work-flow-deal') {
        this.formConfig = { ...row, componentsStatus: 'edit' };
        this.modalConfig.title = '意见反馈处理';
        this.formName = 'DetailPage';
        this.modalConfig.showFooter = true;
        this.openFull();
      }
    },
    beforeSetSearch(val) {
      const item = val;
      if (item.field === 'createTime') {
        item.typeName = 'DatePicker';
        item.type = 'customSearch';
      }

      if (item.field === 'reviewDate') {
        item.typeName = 'DatePicker';
        item.type = 'customSearch';
      }
      return item;
    },
  },
};
