var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    attrs: {
      format: "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd",
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "开始时间",
      "end-placeholder": "结束时间",
    },
    on: { change: _vm.changeTime },
    model: {
      value: _vm.timeValue,
      callback: function ($$v) {
        _vm.timeValue = $$v
      },
      expression: "timeValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }