import Form, { formCreate } from '@/found/components/form';
import Table from './table/index';

formCreate.component('Table', Table);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'packageForm',
    };
  },
  methods: {

  },
  watch: {},
};
