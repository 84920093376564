<template>
  <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="timeValue" @change='changeTime'
    type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" />
</template>

<script>
export default {
  name: 'DatePicker',
  data() {
    return {
      timeValue: undefined,
    };
  },
  props: ['formData', 'value'],
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.timeValue = undefined;
        }
      },
    },
  },
  methods: {
    changeTime(e) {
      this.$emit('getCustomData', e ? e.join(',') : '');
    },
  },
};
</script>
